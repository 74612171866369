import React from "react";
import ClassList from "../../utils/helpers/ClassList";
import SEO from "../SEO";

interface PageProps {
  className?: string;
  seo: {
    title: string;
  };
  name: string;
}

const Page: React.FC<PageProps> = (props) => {
  const { children, seo, className, name } = props;

  return (
    <>
      <SEO title={`${seo.title} | T2B`} />
      <div
        className={ClassList.join("T2B-Page", className, `T2B-Page--${name}`)}
      >
        {children}
      </div>
    </>
  );
};

export default Page;
